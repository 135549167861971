<!--
 * @Author: jiang
 * @Date: 2021-07-12 09:34:19
 * @Description: 流程、要点、制度
-->

<template>
  <el-container
    class="g-page-list"
    style="height: 100%; padding: 0 15px 15px; overflow: hidden;"
  >
    <el-aside
      class="g-bg g-mr-15"
      v-if="userSuperId === userId"
    >
      <el-scrollbar
        y
        view-style="padding: 10px;"
      >
        <dept-tree
          ref="tree"
          @change="onDeptChange"
        ></dept-tree>
      </el-scrollbar>
    </el-aside>
    <el-main class="g-bg g-p-20">
      <el-form
        :model="searchData"
        label-position="top"
        inline
      >
        <el-form-item
          label="任务时间："
          prop="time"
        >
          <el-date-picker
            v-model="searchData.time"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item style="vertical-align: bottom;">
          <c-btn-search
            :show="['search', 'reset']"
            @search="onSearch"
            @reset="onReset"
          ></c-btn-search>
        </el-form-item>
      </el-form>

      <el-table :data="list">
        <el-table-column
          label="ID"
          prop="id"
        ></el-table-column>
        <el-table-column
          label="姓名"
          prop="name"
        ></el-table-column>
        <el-table-column
          label="进行中"
          prop="progress_tasks_count"
        ></el-table-column>
        <el-table-column
          label="收到任务"
          prop="received_tasks_count"
        ></el-table-column>
        <el-table-column
          label="已落实"
          prop="practicable_tasks_count"
        ></el-table-column>
        <el-table-column
          label="逾期"
          prop="overdue_tasks_count"
        ></el-table-column>
        <el-table-column
          label="发布"
          prop="release_agendas_count"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="100"
        >
          <template slot-scope="{ row }">
            <c-btn-table
              :show="['info']"
              @info="onInfo(row)"
            ></c-btn-table>
          </template>
        </el-table-column>
      </el-table>

      <b-pagination
        :page="page"
        @change="fetchList"
      ></b-pagination>
    </el-main>
  </el-container>
</template>

<script>
import DeptTree from '@/components/tree/dept.vue'
import { getUserFeedbackPage } from '@/api/manager'
import { mapState } from 'vuex'

export default {
  components: {
    DeptTree,
  },
  data() {
    return {
      userSuperId: parseInt(process.env.VUE_APP_SUPER_USER_ID),
      searchData: {
        time: [],
      },
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      list: [],
      currentDept: '',
      currentDeptName: '',
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id,
      userDepartmentId: state => state.userInfo.department.id,
      userDepartmentName: state => state.userInfo.department.name,
    }),
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      const deptId = this.currentDept || this.userDepartmentId

      const params = {
        start_date: this.searchData.time[0],
        end_date: this.searchData.time[1],
      }
      getUserFeedbackPage(deptId, params).then(res => {
        this.list = res.data
        this.page.total = res.total
      })
    },
    onDeptChange(id) {
      this.currentDept = id
      // this.$refs.tree.getName(id).then(res => {
      //   this.currentDeptName = res
      // })
      this.fetchList()
    },
    onSearch() {
      this.page.current = 1
      this.fetchList()
    },
    onReset() {
      this.$refs.form.resetFields()
      this.onSearch()
    },
    onInfo(row) {
      this.$router.push({
        path: '/manager/feedback-user',
        query: { name: row.name, id: row.id },
      })
    },
  },
}
</script>
